import jwtAxios from '@common/services/auth/jwt-auth';
import {
  appIntl,
  buildQueryString,
  parseParams,
} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_LIST_BLOG_SUCCESS,
  GET_LIST_BLOG_FAIL,
  GET_LIST_ARTICLE_SUCCESS,
  GET_LIST_ARTICLE_FAIL,
  GET_LIST_CATEGORY_DOCUMENT_DETAIL_SUCCESS,
  GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS,
  GET_DETAILS_ART_DOCUMENT,
  GET_DETAILS_ART_DOCUMENT_SUCCESS,
  GET_DETAILS_ART_DOCUMENT_FAILS,
} from 'shared/constants/ActionTypes';
import {
  URL_GET_LIST_BLOG,
  URL_GET_LIST_ARTICLE,
  URL_ARTICLE_DOCUMENT_LIST,
  URL_ARTICLE_CATEGORY_LIST,
  URL_ARTICLE_DOCUMENT_DETAIL,
  URL_ARTICLE_CATEGORY_DETAIL,
  URL_INCREASE_DOWNLOAD,
} from 'shared/constants/api-url/lawdissemination';
import {API_CAT} from 'shared/constants/UrlApis';

export const onGetListBlog = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    jwtAxios
      .get(URL_GET_LIST_BLOG)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_LIST_BLOG_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_LIST_BLOG_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_LIST_BLOG_FAIL});
      });
  };
};

export const onGetListArticle = (linkGet) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    jwtAxios
      .get(URL_GET_LIST_ARTICLE, {
        params: {
          link: linkGet,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_LIST_ARTICLE_SUCCESS,
            payload: data.data,
          });
        } else {
          dispatch({
            type: GET_LIST_ARTICLE_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_LIST_ARTICLE_FAIL});
      });
  };
};

export const onGetArticleByCategory = (slug, router, dataFilter, dataPagi) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    const url = `${URL_ARTICLE_DOCUMENT_LIST}?${buildQueryString(
      parseParams(dataFilter),
    )}`;
    jwtAxios
      .get(url, {
        params: {
          pageIndex: dataPagi,
          link: slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_LIST_CATEGORY_DOCUMENT_DETAIL_SUCCESS,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          if (slug === '/') {
            dispatch({
              type: GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS,
            });
          } else {
            dispatch({
              type: GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS,
            });
            router.push('/404');
          }
        } else {
          dispatch({
            type: GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_LIST_CATEGORY_DOCUMENT_DETAIL_FAILS,
        });
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};

export const onGetDetailDocument = (slug, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_DETAILS_ART_DOCUMENT});
    jwtAxios
      .get(URL_ARTICLE_DOCUMENT_DETAIL, {
        params: {
          slug: slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_DETAILS_ART_DOCUMENT_SUCCESS,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: GET_DETAILS_ART_DOCUMENT_FAILS,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_DETAILS_ART_DOCUMENT_FAILS,
        });
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};

export const onGetDetailArticle = (slug, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: GET_DETAILS_ART_DOCUMENT});
    jwtAxios
      .get(URL_ARTICLE_CATEGORY_DETAIL, {
        params: {
          slug: slug,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_DETAILS_ART_DOCUMENT_SUCCESS,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: GET_DETAILS_ART_DOCUMENT_FAILS,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_DETAILS_ART_DOCUMENT_FAILS,
        });
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};

export const onIncreaseDownload = (slug) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_INCREASE_DOWNLOAD, {
        slug: slug,
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
        if (error.response.status === 404) {
          router.push('/404');
        }
      });
  };
};
