import jwtAxios from '@common/services/auth/jwt-auth';
import {formErrorBE} from '@common/utility/helper/FormHelper';
import serialize from '@common/utility/helper/ObjectToFormData';
import {appIntl} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_DATA_SLOGAN_CLIENT,
} from 'shared/constants/ActionTypes';
import {
  URL_SLOGAN_UPDATE,
  URL_SLOGAN_CLIENT_LIST,
} from 'shared/constants/api-url/banner';

import {API_CAT} from 'shared/constants/UrlApis';

export const onGetDataSlogan = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_SLOGAN_CLIENT_LIST)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_DATA_SLOGAN_CLIENT, payload: response});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};
