import jwtAxios from '@common/services/auth/jwt-auth';
import {formErrorBE} from '@common/utility/helper/FormHelper';
import serialize from '@common/utility/helper/ObjectToFormData';
import {appIntl} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_LAYOUT_CLIENT,
  GET_LAYOUT_LIST,
  SHOW_MESSAGE,
} from 'shared/constants/ActionTypes';
import {
  URL_LAYOUT_UPDATE,
  URL_LAYOUT_GET_LIST,
  URL_LAYOUT_CLIENT,
} from 'shared/constants/api-url/layout';

import {API_CAT, API_CAT_DANHMUC} from 'shared/constants/UrlApis';

export const onGetLayoutList = () => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_LAYOUT_GET_LIST)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_LAYOUT_LIST, payload: response});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onUpdateSelectedLayout = (layout) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_LAYOUT_UPDATE, layout)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetLayoutList());
          if (layout.isDisplay === true) {
            dispatch({
              type: SHOW_MESSAGE,
              payload: messages['message.layoutDisplay'],
            });
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: messages['message.layoutHide'],
            });
          }
        } else {
          formErrorBE(data, dispatch);
        }
      })
      .catch((error) => {
        formErrorBE(error, dispatch);
      });
  };
};

export const onGetLayoutClient = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_LAYOUT_CLIENT)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_LAYOUT_CLIENT, payload: response});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};
