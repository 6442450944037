import jwtAxios from '@common/services/auth/jwt-auth';
import {
  appIntl,
  buildQueryString,
  parseParams,
} from '@common/utility/helper/Utils';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_REFLECT_LIST,
  SEND_REFLECT,
  SENT_REFLECT,
  REFLECT_GET_FIELD,
  GET_STATUS,
  GET_UNIT,
  GET_REFLECT_DETAIL,
  GET_REFLECT_CLIENT_FAIL,
  GET_REFLECT_CLIENT,
  GET_REFLECT_DETAIL_CLIENT,
  GET_REFLECT_FIELD_CLIENT_FAIL,
  GET_REFLECT_STATUS_CLIENT_FAIL,
  GET_REFLECT_DETAIL_CLIENT_FAIL,
  REFLECT_PROCESS,
  REFLECT_PROCESSED,
  GET_REFLECT_NOTIFICATION,
  GET_REFLECT_NOTI_NO_FILTER,
  GET_MY_REFLECTION,
} from 'shared/constants/ActionTypes';
import {
  URL_REFLECT_GET_LIST,
  URL_STATUS_GET_LIST,
  URL_REFLECT_DETAIL,
  URL_STATUS_CREATE,
  URL_UNIT_DROP_GET_LIST,
  URL_REFLECT_REJECT,
  URL_REFLECT_RECEIVE,
  URL_REFLECT_UNIT_REJECT,
  URL_REFLECT_PROCESS,
  URL_REFLECT_NOTIFICATION,
  URL_REFLECT_APPROVE,
  URL_MY_REFLECTION,
} from 'shared/constants/api-url/reflect';
import {URL_LIST_DON_VI} from 'shared/constants/api-url/category';
import {URL_REFLECTION_FIELD_GET_LIST} from 'shared/constants/api-url/reflectField';
import {API_CAT_PAKN, API_CAT_DANHMUC} from 'shared/constants/UrlApis';
import {formErrorBE} from '@common/utility/helper/FormHelper';
import serialize from '@common/utility/helper/ObjectToFormData';
import {encryptByAES} from '@common/utility/Encrypt';

jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;

export const onGetReflectList = (dataFilter, setCurent) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: GET_REFLECT_CLIENT});
    const url = `${URL_REFLECT_GET_LIST}?${buildQueryString(
      parseParams({
        ...dataFilter,
        LinhVucId: dataFilter?.LinhVucId?.value,
        TrangThaiId: dataFilter?.TrangThaiId?.value,
        DonViId: dataFilter?.DonViId?.value,
        SearchTerm: dataFilter?.searchFlg ? dataFilter?.SearchTerm : null,
      }),
    )}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_REFLECT_LIST, payload: data.data});
          setCurent((prevBlogs) => [...prevBlogs, ...data.data.data]);
        } else {
          dispatch({
            type: GET_REFLECT_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_CLIENT_FAIL,
        });
      });
  };
};
export const onGetListReflectField = () => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_REFLECTION_FIELD_GET_LIST)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: REFLECT_GET_FIELD, payload: data.data});
        } else {
          dispatch({
            type: GET_REFLECT_FIELD_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_FIELD_CLIENT_FAIL,
        });
      });
  };
};
export const onGetListTrangThai = () => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_STATUS_GET_LIST)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_STATUS, payload: data.data});
        } else {
          dispatch({
            type: GET_REFLECT_STATUS_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_STATUS_CLIENT_FAIL,
        });
      });
  };
};

export const onGetListDonVi = () => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(URL_UNIT_DROP_GET_LIST)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_UNIT, payload: data.data});
        } else {
          dispatch({
            type: GET_REFLECT_STATUS_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_STATUS_CLIENT_FAIL,
        });
      });
  };
};

export const onCreateReflection = (field, dataFilter, router) => {
  const {messages} = appIntl();
  let formData = serialize({
    ...field,
    TenCaNhanToChuc: encryptByAES(
      field.TenCaNhanToChuc,
      process.env.NEXT_SECRET_KEY_HASH,
    ),
    Email: encryptByAES(field.Email, process.env.NEXT_SECRET_KEY_HASH),
    SoDienThoai: encryptByAES(
      field.SoDienThoai,
      process.env.NEXT_SECRET_KEY_HASH,
    ),
    DiaChi: encryptByAES(field.DiaChi, process.env.NEXT_SECRET_KEY_HASH),
  });
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: SEND_REFLECT});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_STATUS_CREATE, formData)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.reflectionCreated'],
          });
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SENT_REFLECT});
          if (!field.continueCreate) {
            router.push('/phan-anh-kien-nghi');
            dispatch(onGetReflectList(dataFilter));
          }
          dispatch(onGetReflectListNotificationNoFilter());
        } else {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onGetDetailReflect = (slug, router) => {
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  const url = `${URL_REFLECT_DETAIL}?Slug=${slug}`;
  return (dispatch) => {
    dispatch({type: GET_REFLECT_DETAIL_CLIENT});
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({
            type: GET_REFLECT_DETAIL,
            payload: data.data,
          });
        } else if (data.data.statusCode === 404) {
          router.push('/404');
        } else {
          dispatch({
            type: GET_REFLECT_DETAIL_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_DETAIL_CLIENT_FAIL,
        });
        // if (error.response.status === 404) {
        //   router.push('/404');
        // }
      });
  };
};

export const onRejectReflection = (field, handleCloseTuChoi, slug, router) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: REFLECT_PROCESS});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_REFLECT_REJECT, field)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          handleCloseTuChoi();
          dispatch(onGetDetailReflect(slug, router));
          dispatch(onGetReflectListNotificationNoFilter());

          dispatch({type: REFLECT_PROCESSED});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.reflectionRejected'],
          });
        } else {
          dispatch({type: REFLECT_PROCESSED});
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      })
      .catch((error) => {
        dispatch({type: REFLECT_PROCESSED});
        console.error(error.message);
      });
  };
};

export const onChangeUnitReflection = (
  field,
  handleCloseTiepNhan,
  slug,
  router,
) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: REFLECT_PROCESS});
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_REFLECT_RECEIVE, field)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          handleCloseTiepNhan();
          dispatch(onGetDetailReflect(slug, router));
          dispatch(onGetReflectListNotificationNoFilter());
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.reflectionReceived'],
          });
          dispatch({type: REFLECT_PROCESSED});
        } else {
          dispatch({type: REFLECT_PROCESSED});
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      })
      .catch((error) => {
        dispatch({type: REFLECT_PROCESSED});
        console.error(error.message);
      });
  };
};

export const onUnitRejectReflection = (
  field,
  handleCloseTraLai,
  slug,
  router,
) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({type: REFLECT_PROCESS});
    jwtAxios
      .post(URL_REFLECT_UNIT_REJECT, field)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          handleCloseTraLai();
          dispatch(onGetDetailReflect(slug, router));
          dispatch(onGetReflectListNotificationNoFilter());
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.reflectionRejected'],
          });
          dispatch({type: REFLECT_PROCESSED});
        } else {
          dispatch({type: REFLECT_PROCESSED});
          dispatch({type: FETCH_ERROR, payload: data.message});
        }
      })
      .catch((error) => {
        dispatch({type: REFLECT_PROCESSED});
        console.error(error.message);
      });
  };
};

export const onProcessReflection = (field, handleCloseXuLy, slug, router) => {
  const {messages} = appIntl();
  let formData = serialize(field);
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    dispatch({type: REFLECT_PROCESS});
    jwtAxios
      .post(URL_REFLECT_PROCESS, formData)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          handleCloseXuLy();
          dispatch(onGetDetailReflect(slug, router));
          dispatch(onGetReflectListNotificationNoFilter());
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.reflectionProcess'],
          });
          dispatch({type: REFLECT_PROCESSED});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
          dispatch({type: REFLECT_PROCESSED});
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};
export const onGetReflectListNotification = (dataFilter, setCurent) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    const url = `${URL_REFLECT_NOTIFICATION}?${buildQueryString(
      parseParams({
        ...dataFilter,
        LinhVucId: dataFilter?.LinhVucId?.value,
        TrangThaiId: dataFilter?.TrangThaiId?.value,
        DonViId: dataFilter?.DonViId?.value,
        SearchTerm: dataFilter?.searchFlg ? dataFilter?.SearchTerm : null,
      }),
    )}`;
    // const url = URL_REFLECT_NOTIFICATION;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_REFLECT_NOTIFICATION, payload: data.data});
          setCurent((prevBlogs) => [...prevBlogs, ...data.data.data]);
        } else {
          dispatch({
            type: GET_REFLECT_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_CLIENT_FAIL,
        });
      });
  };
};

export const onApproveReflection = (id, handleCloseDuyet, slug, router) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  const field = {
    id: id,
  };
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_REFLECT_APPROVE, field)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          handleCloseDuyet();
          dispatch(onGetDetailReflect(slug, router));
          dispatch(onGetReflectListNotificationNoFilter());
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.reflectionApproved'],
          });
          dispatch({type: REFLECT_PROCESSED});
        } else {
          dispatch({type: FETCH_ERROR, payload: data.data.message});
          dispatch({type: REFLECT_PROCESSED});
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onGetReflectListNotificationNoFilter = () => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    const url = URL_REFLECT_NOTIFICATION;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_REFLECT_NOTI_NO_FILTER, payload: data.data});
        } else {
          dispatch({
            type: GET_REFLECT_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_CLIENT_FAIL,
        });
      });
  };
};

export const onGetMyeflection = (dataFilter, setCurent) => {
  const {messages} = appIntl();
  jwtAxios.defaults.baseURL = `${API_CAT_PAKN}`;
  return (dispatch) => {
    dispatch({type: GET_REFLECT_CLIENT});
    const url = `${URL_MY_REFLECTION}?${buildQueryString(
      parseParams({
        ...dataFilter,
        LinhVucId: dataFilter?.LinhVucId?.value,
        TrangThaiId: dataFilter?.TrangThaiId?.value,
      }),
    )}`;
    // const url = URL_REFLECT_NOTIFICATION;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_MY_REFLECTION, payload: data.data});
          setCurent((prevBlogs) => [...prevBlogs, ...data.data.data]);
        } else {
          dispatch({
            type: GET_REFLECT_CLIENT_FAIL,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: GET_REFLECT_CLIENT_FAIL,
        });
      });
  };
};
