import jwtAxios from '@common/services/auth/jwt-auth';
import {formErrorBE} from '@common/utility/helper/FormHelper';
import serialize from '@common/utility/helper/ObjectToFormData';
import {
  appIntl,
  buildQueryString,
  parseParams,
} from '@common/utility/helper/Utils';
import {
  CREATE_NEW_CATEGORY,
  FETCH_ERROR,
  DELETE_CATEGORY,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  GET_CATEGORY_LIST,
  UPDATE_CATEGORY_DETAIL,
  LOAD_TREE_CATEGORY,
  LOAD_TREE_CATEGORY_SUCCESS,
  GET_TREE_CATEGORY,
  GET_CATEGORY_PARENT,
  GET_LIST_IMAGE,
} from 'shared/constants/ActionTypes';
import {
  URL_CATEGORY_CHILDRENT_DETAIL,
  URL_CATEGORY_CREATE,
  URL_CATEGORY_DELETE,
  URL_CATEGORY_GET_DETAIL,
  URL_CATEGORY_GET_LIST,
  URL_CATEGORY_GET_PARENT,
  URL_GET_CATEGORY_PARENT,
  URL_GET_LIST_IMAGE,
  URL_CATEGORY_UPLOAD_FILE,
} from 'shared/constants/api-url/category';
import {API_CAT, API_CAT_DANHMUC} from 'shared/constants/UrlApis';

export const onGetCategoryList = (dataFilter) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  const url = `${URL_CATEGORY_GET_LIST}?${buildQueryString(
    parseParams(dataFilter),
  )}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CATEGORY_LIST, payload: response});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onDeleteCategory = (categoryIds, dataFilter) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  const url = `${URL_CATEGORY_DELETE}/${categoryIds}`;
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .delete(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetCategoryList(dataFilter));
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.categoryDeleted'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onUpdateSelectedCategory = (category, dataFilter, onToggle) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  let formData = serialize(category);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_CATEGORY_CREATE, formData)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetCategoryList(dataFilter));
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.categoryUpdated'],
          });
          onToggle(false);
        } else {
          formErrorBE(data, dispatch, onToggle);
        }
      })
      .catch((error) => {
        formErrorBE(error, dispatch, onToggle);
      });
  };
};

export const onUpdateCategoryUpload = (
  category,
  dataGet,
  dataFilter,
  onToggle,
) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  let formData = new FormData();
  formData.append('Id', dataGet.id);
  if (dataGet.fileIds !== null && dataGet.fileIds !== undefined) {
    formData.append('FileIds', dataGet.fileIds);
  }
  if (dataGet.files !== null && dataGet.files !== undefined) {
    Array.from(dataGet.files).forEach((file) => {
      formData.append('Files', file);
    });
  }
  let formData2 = serialize(category);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_CATEGORY_UPLOAD_FILE, formData)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
        } else {
          formErrorBE(data, dispatch, onToggle);
        }
      })
      .catch((error) => {
        formErrorBE(error, dispatch, onToggle);
      });
    jwtAxios
      .post(URL_CATEGORY_CREATE, formData2)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetCategoryList(dataFilter));
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.categoryUpdated'],
          });
          onToggle(false);
        } else {
          formErrorBE(data, dispatch, onToggle);
        }
      })
      .catch((error) => {
        formErrorBE(error, dispatch, onToggle);
      });
  };
};

export const onCreateCategory = (category, dataFilter, onToggle) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  let formData = serialize(category);
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post(URL_CATEGORY_CREATE, formData)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch(onGetCategoryList(dataFilter));
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.categoryCreated'],
          });
          onToggle(false);
        } else {
          formErrorBE(data, dispatch, onToggle);
        }
      })
      .catch((error) => {
        formErrorBE(error, dispatch, onToggle);
      });
  };
};

export const onGetCategoryTreeHomeList = (type, name, currentPage) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  const page = currentPage ? currentPage : 0;
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    dispatch({type: LOAD_TREE_CATEGORY});
    jwtAxios
      .get(URL_CATEGORY_GET_PARENT, {
        params: {
          type: type,
          name: name,
          page: page,
        },
      })
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: LOAD_TREE_CATEGORY_SUCCESS});
          dispatch({
            type: GET_TREE_CATEGORY,
            payload: data.data,
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onGetCategoryParent = () => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = `${URL_GET_CATEGORY_PARENT}?${buildQueryString(
      parseParams({IsGetAll: true}),
    )}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CATEGORY_PARENT, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};

export const onGetListImage = (id) => {
  jwtAxios.defaults.baseURL = `${API_CAT_DANHMUC}`;
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const url = `${URL_GET_LIST_IMAGE}?${buildQueryString(
      parseParams({Id: id}),
    )}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_LIST_IMAGE, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
};
