import {useAuthMethod} from '@common/utility/AuthHooks';
import useSession from '@common/utility/hooks/useSession';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  onGetLatestPost,
  onGetMostViewPost,
  showRightSidebar,
} from 'redux/actions';
import {onGetCategoryTreeHomeList} from 'redux/actions/Category';
import {onGetBannerClientListV2} from 'redux/actions/guess/Banner';
import {onGetLinkClientList} from 'redux/actions/guess/Link';
import {onGetLayoutClient} from 'redux/actions/Layout';
import {onGetReflectListNotificationNoFilter} from 'redux/actions/guess/Reflect';
import {onGetDataSlogan} from 'redux/actions/guess/Slogan';
import {onGetViewClient} from 'redux/actions/analytics';
import {onGetListBlog} from 'redux/actions/guess/LawDissemination';
import {
  onGetActivityClientList,
  onGetActivityClientImage,
} from 'redux/actions/guess/Activity';
import {toggleNavCollapsedHide} from 'redux/actions';
import {defaultPageIndex} from 'shared/constants/AppConst';
import {onGetDataFooter} from 'redux/actions/Customer';

const withData = (ComposedComponent) => (props) => {
  const dispatch = useDispatch();
  const [value] = useSession('rememberMe');
  const {logout} = useAuthMethod();
  const {rememberMe} = useSelector(({authentication}) => authentication);
  const url = window.location.pathname;
  useEffect(() => {
    console.log(url);
    if (!url.includes('nguoi-dan-gui-danh-gia')) {
      dispatch(toggleNavCollapsedHide());
      //bo cuc
      dispatch(onGetLayoutClient());

      dispatch(showRightSidebar());
      dispatch(onGetBannerClientListV2());
      dispatch(onGetCategoryTreeHomeList());
      //most view
      dispatch(onGetMostViewPost());

      //latest
      dispatch(onGetLatestPost());

      dispatch(onGetLinkClientList());

      // notification reflect
      dispatch(onGetReflectListNotificationNoFilter());

      // Category blog
      dispatch(onGetListBlog());

      // slogan
      dispatch(onGetDataSlogan());

      //analytics
      dispatch(onGetViewClient());

      //activities
      dispatch(onGetActivityClientList(defaultPageIndex));
      dispatch(onGetActivityClientImage());
    } else {
      dispatch(onGetDataFooter());
    }
  }, [dispatch]);

  useEffect(() => {
    if (value === undefined && (rememberMe === false || rememberMe === null)) {
      logout();
    }
  }, []);
  return <ComposedComponent {...props} />;
};

export default withData;
