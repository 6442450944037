import jwtAxios from '@common/services/auth/jwt-auth';
import {
  GET_ACTIVITY_CLIENT,
  GET_ACTIVITY_CLIENT_DETAIL,
  GET_ACTIVITY_CLIENT_DETAIL_SUCCESS,
  GET_ACTIVITY_CLIENT_DETAIL_FAIL,
  GET_ACTIVITY_CLIENT_IMAGE,
} from 'shared/constants/ActionTypes';
import {
  URL_ACTIVITY_CLIENT_LIST,
  URL_ACTIVITY_CLIENT_DETAIL,
  URL_ACTIVITY_CLIENT_IMAGE,
} from 'shared/constants/api-url/activity';
import {API_CAT} from 'shared/constants/UrlApis';

export const onGetActivityClientList = (pageIndex) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    const url = `${URL_ACTIVITY_CLIENT_LIST}?PageIndex=${pageIndex}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: GET_ACTIVITY_CLIENT, payload: response});
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const onGetActivityClientDetail = (slug) => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    const url = `${URL_ACTIVITY_CLIENT_DETAIL}?Slug=${slug}`;
    dispatch({type: GET_ACTIVITY_CLIENT_DETAIL});
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({
            type: GET_ACTIVITY_CLIENT_DETAIL_SUCCESS,
            payload: response,
          });
        }
      })
      .catch((error) => {
        dispatch({type: GET_ACTIVITY_CLIENT_DETAIL_FAIL});
        console.error(error);
      });
  };
};
export const onGetActivityClientImage = () => {
  jwtAxios.defaults.baseURL = `${API_CAT}`;
  return (dispatch) => {
    const url = `${URL_ACTIVITY_CLIENT_IMAGE}`;
    jwtAxios
      .get(url)
      .then((data) => {
        if (data.data.statusCode === 200) {
          let response = data.data;
          dispatch({type: GET_ACTIVITY_CLIENT_IMAGE, payload: response});
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
