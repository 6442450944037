export const URL_REFLECT_GET_LIST = 'phan-anh/list';
export const URL_REFLECT_DETAIL = 'phan-anh/get';
export const URL_REFLECT_RECEIVE = 'phan-anh/tiep-nhan';
export const URL_REFLECT_REJECT = 'phan-anh/tu-choi';
export const URL_REFLECT_UNIT_REJECT = 'phan-anh/tra-lai';
export const URL_REFLECT_APPROVE = 'phan-anh/duyet';
export const URL_REFLECT_CHANGE_UNIT = 'phan-anh/chuyen-don-vi';
export const URL_REFLECT_PROCESS = 'phan-anh/xu-ly';
export const URL_STATUS_CREATE = 'phan-anh/send';
export const URL_STATUS_GET_LIST = 'trang-thai/list';
export const URL_UNIT_DROP_GET_LIST = 'don-vi/dropdown';
export const URL_DELETE_REFLECT = 'phan-anh/delete';
export const URL_EXPORT_REFLECT = 'phan-anh/xuat-excel';
export const URL_CHECK_CONFIG_REFLECT = 'phan-anh/check-cau-hinh';
export const URL_REPORT_REFLECT = 'phan-anh/report';
export const URL_PRINT_DETAIL_REFLECT = 'phan-anh/in-phan-anh';
export const URL_REFLECT_NOTIFICATION = 'phan-anh/thong-bao';
export const URL_MY_REFLECTION = 'phan-anh/nguoi-dung';
