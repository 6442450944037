//document type
export const URL_DOCUMENT_TYPE_CREATE = `/loai-van-ban/submit`;
export const URL_DOCUMENT_TYPE_DELETE = `/loai-van-ban/delete`;
export const URL_DOCUMENT_TYPE_GET_LIST = `/loai-van-ban/list`;

//agency issued
export const URL_AGENCY_ISSUED_CREATE = `/co-quan-ban-hanh/submit`;
export const URL_AGENCY_ISSUED_DELETE = `/co-quan-ban-hanh/delete`;
export const URL_AGENCY_ISSUED_GET_LIST = `/co-quan-ban-hanh/list`;

//document
export const URL_DOCUMENT_CREATE = `/van-ban/submit`;
export const URL_DOCUMENT_DELETE = `/van-ban/delete`;
export const URL_DOCUMENT_GET_LIST = `/van-ban/list`;
export const URL_LIST_PUBLISHER_DOCUMENT = `/common/dropdown-nguoi-duyet-bai-van-ban`;
export const URL_LIST_AGENCY_ISSUED = `/common/dropdown-co-quan-ban-hanh`;
export const URL_LIST_DOCUMENT_TYPE = `/common/dropdown-loai-van-ban`;
export const URL_LIST_CATEGORY_DOCUMENT = `/common/dropdown-danh-muc-van-ban`;
export const URL_GET_DOCUMENT_DETAIL = `/van-ban/get`;
export const URL_SUBMIT_ONE_PUBLISHED = `/van-ban/set-publish`;

//guess
export const URL_GET_LIST_BLOG = `/pbpl/list-danh-muc-block`;
export const URL_GET_LIST_ARTICLE = `/pbpl/trang-chu`;
export const URL_ARTICLE_DOCUMENT_LIST = `/pbpl/list-tin-bai-van-ban`;
export const URL_GET_DETAILS_ART_DOCUMENT = `/pbpl/chi-tiet`;
export const URL_ARTICLE_DOCUMENT_DETAIL = `/pbpl/chi-tiet-van-ban`;
export const URL_ARTICLE_CATEGORY_DETAIL = `/pbpl/chi-tiet-tin-bai`;
export const URL_INCREASE_DOWNLOAD = '/pbpl/luot-tai-van-ban';
