import React, {useEffect} from 'react';
import Layouts from '../../core/AppLayout/Layouts';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '../../utility/AppContextProvider/LayoutContextProvider';
import {useSidebarActionsContext} from '../../utility/AppContextProvider/SidebarContextProvider';
import {useRouter} from 'next/router';
import {FooterType, NavStyle} from 'shared/constants/AppEnums';

const withLayout = (ComposedComponent) => (props) => {
  const {navStyle} = useLayoutContext();
  const AppLayout = Layouts[navStyle];

  const {updateNavStyle, setFooterType} = useLayoutActionsContext();
  const {updateMenuStyle, setSidebarBgImage} = useSidebarActionsContext();
  const router = useRouter();

  useEffect(() => {
    updateNavStyle(NavStyle.H_DEFAULT);
    setFooterType(FooterType.FLUID);
    if (router.query.menuStyle) updateMenuStyle(router.query.menuStyle);
    // if (router.query.sidebarImage) setSidebarBgImage(true);
  }, []);

  const url = window.location.pathname;

  return url == '/nguoi-dan-gui-danh-gia' ? (
    <ComposedComponent {...props} />
  ) : (
    <AppLayout>
      <ComposedComponent {...props} />
    </AppLayout>
  );
};

export default withLayout;
