export const fetchTotalUsers = async () => {
  // make request
  const res = await fetch('/api/analytics/total-users', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};

export const fetchUsersByCountry = async () => {
  // make request
  const res = await fetch('/api/analytics/users-by-country', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};

export const fetchUserEngagementDuration = async () => {
  // make request
  const res = await fetch('/api/analytics/user-engagement-duration', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};

export const fetchPageViews = async () => {
  // make request
  const res = await fetch('/api/analytics/page-views', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};

export const fetchUsersByPlatform = async () => {
  // make request
  const res = await fetch('/api/analytics/users-by-platform', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};

export const fetchEventCounts = async () => {
  // make request
  const res = await fetch('/api/analytics/event-counts', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};

export const fetchViewCounts = async () => {
  // make request
  const res = await fetch('/api/analytics/data', {
    method: 'GET',
  });
  // decode response
  const resJson = await res.json();
  // check error
  if (!res.ok || !resJson.success) {
    throw new Error('Analytics fetch error');
  }
  // return data
  return resJson.data;
};
